import $ from 'jquery';
import magnificPopup from "magnific-popup";


$(document).ready(function(){ 
    myConnections();
    myConnectionsStatic();
    $("#user-search-status").on("click", myConnectionsStatic); 
}); //document ready ends

window.myConnectionsStatic = function(){
     
     if($('.js_modal').length > 0){
		$('.js_modal').magnificPopup({
			type: 'inline',
			closeBtnInside: true
		});
      }
      
      if($('.js_click_me2').length > 0){ //for curency selection modal
        
		$('.js_click_me2').on('click', function () {
			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-currency'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: false,
				modal: true
			});
		});
	  };
	  
	  if($('.js_click_me_t_zone').length > 0){ //for curency selection modal
        
		$('.js_click_me_t_zone').on('click', function () {
// 			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-zone'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: false,
				modal: true
			});
		});
	  };
	  
	  if($('.js_click_me3').length > 0){ //for updates modal
		$('.js_click_me3').on('click', function () {
			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-updates'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: false,
				modal: true
			});
		});
	  };
	  
	  if($('.js_click_me4').length > 0){ //for language
		$('.js_click_me4').on('click', function () {
			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-locale'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: false,
				modal: true
			});
		});
	  };
	  

	  
	  if($('.js_click_me5').length > 0){ //for object create update
		$('.js_click_me5').on('click', function () {
// 			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-edit-object'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: true,
				modal: true
			});
		});
	  };
	  
	   if($('.js_click_me_uni').length > 0){ //for object create update
		$('.js_click_me_uni').on('click', function () {
// 			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: ('#'+ $(this)[0].dataset.target)
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: true,
				modal: true
			});
		});
	  };
	  
	  	  if($('.js_click_non_close').length > 0){ //for modals that should not close 
	    	$('.js_click_non_close').on('click', function () {
// 			$('.fix-card-link').addClass('visible');
            	$.magnificPopup.open({
				items: {
					src: $(this).attr('href')
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: true,
				modal: true
			});
   
 
		});
	  };
	  
      avatarColor();
      
     urlifyComment();
     
 };
 
window.avatarColor = function() {
    
      if($('.no-avatar-present').length > 0){
        $('.no-avatar-present').each(function(){
          var name = $(this)[0].dataset.name;
          var color = stringToHslColor(name, 30, 70);
          $(this).css({"background-color" : color});
        });
      };
      
      function stringToHslColor(str, s, l) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        };
        var h = hash % 360;
        return 'hsl('+h+', '+s+'%, '+l+'%)';
      };
      
};

window.urlifyComment = function() {
  if ($(".comment-just-body").length > 0) {
    $(".comment-just-body:not(.urlified)").each(function () {
      var body = $(this).html();
      $(this).html(window.urlifyComment.urlify(body));
      $(this).addClass("urlified"); // To avoid double urlification
    });
  }
  $(".comment-wrap .trix-content a").attr("target", "_blank"); // For trix links
};

window.urlifyComment.urlify = function(text) {
  // Updated Regex: includes query parameters and stops at proper delimiters
  const urlRegex = /(\b(https?|ftp):\/\/[^\s<>"'`]+(?:\?[^\s<>"'`]*)?)/gim;

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = text;

  function processNode(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      const parent = node.parentNode;
      const matches = [...node.textContent.matchAll(urlRegex)];

      if (matches.length > 0) {
        let lastIndex = 0;

        matches.forEach(match => {
          const url = match[0];
          const startIndex = match.index;
          const endIndex = startIndex + url.length;

          // Add preceding text
          if (startIndex > lastIndex) {
            const precedingText = node.textContent.slice(lastIndex, startIndex);
            parent.insertBefore(document.createTextNode(precedingText), node);
          }

          // Process URL
          const domain = new URL(url).hostname;
          let replacement;

          if (domain === "www.loom.com") {
            const loomMatch = url.match(/https:\/\/www\.loom\.com\/share\/([a-zA-Z0-9]+)/);
            replacement = document.createElement('div');
            replacement.style.position = 'relative';
            replacement.style.paddingBottom = '60.57%';
            replacement.style.height = '0';
            replacement.innerHTML = `<iframe src="https://www.loom.com/embed/${loomMatch[1]}" frameborder="0" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>`;
          } else if (domain === "www.youtube.com" || domain === "youtu.be") {
            const ytMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be\.com\/watch\?v=)([a-zA-Z0-9_-]{11})/);
            replacement = document.createElement('div');
            replacement.classList.add('video');
            replacement.innerHTML = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${ytMatch[1]}" title="YouTube" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture" allowfullscreen></iframe>`;
          } else if (domain === "vimeo.com") {
            const vimeoMatch = url.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);
            const locale = navigator.language || navigator.userLanguage;
            replacement = document.createElement('div');
            replacement.classList.add('video');
            replacement.innerHTML = `<iframe src="https://player.vimeo.com/video/${vimeoMatch[1]}?texttrack=${locale}&byline=0&title=0&controls=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
          } else {
            replacement = document.createElement('a');
            replacement.href = url;
            replacement.target = '_blank';
            replacement.textContent = url.length > 35 ? `${url.substring(0, 35)}...` : url;
          }

          parent.insertBefore(replacement, node);
          lastIndex = endIndex;
        });

        // Add remaining text
        const remainingText = node.textContent.slice(lastIndex);
        if (remainingText) {
          parent.insertBefore(document.createTextNode(remainingText), node);
        }

        parent.removeChild(node);
      }
    } else if (node.nodeType === Node.ELEMENT_NODE && !node.closest('a, .mention, .badge, .stat-info, action-text-attachment')) {
      Array.from(node.childNodes).forEach(processNode);
    }
  }

  Array.from(tempDiv.childNodes).forEach(processNode);
  return tempDiv.innerHTML;
};


   
    
function myConnections() {
    
    
    
    //copy link in invite modal
    if($('.copy-invitation-link').length > 0){
        $(document).on("click", ".copy-invitation-link", function(){
            
         var parent = $(this).parents(".invite-link");
         var copyText = parent.find(".invitation-link")[0];
            
         // var copyText = $(".invitation-link")[0];
          var afterText = parent.find(".copy-btn-text");
          /* Select the text field */
          copyText.select(); 
          copyText.setSelectionRange(0, 99999); /*For mobile devices*/
          
          /* Copy the text inside the text field */
          document.execCommand("copy");
          
          afterText.html(afterText[0].dataset.afteraction);
        });
    };
    
    
    //hiding invitation from my-connections
 
      $(document).on("click", '.hide-invitation', function(){
          console.log('gidee!');
          var element = $(this);
          var originalText = $(this).html();
          $(this).html("<span class='fa fa-spinner fa-spin'> </span>");
          var parent = $(this).parents("tr");
          
          $.ajax({
          url: "/hide_invitation?id="+$(this)[0].dataset.id,
          type: "GET",
          success: function(data) {
            parent.hide();
          },
          error: function(data) {
            element.html(originalText);
            alert("could not hide this invitation, please try later");
          },
        });
      });
 
    
    //resending-invitation from my-connections table
 
        $(document).on("click", '.resend-invitation', function(){
        console.log('gidee!');
         var element = $(this);
         var originalText = $(this).html();
         $(this).html("<span class='fa fa-spinner fa-spin'> </span>");
         var parent = $(this).parents("tr");
         var timeStamp = parent.find(".invited-when");
         var link = $(this)[0].dataset.practitioner == "true" ? "/send_invitation_to_colleague" : "/send_invitation_to_patient"
         
         $.ajax({
          url: link + "?name="+$(this)[0].dataset.name + "&receiver=" + $(this)[0].dataset.email + "&sender_name=" + $(this)[0].dataset.sender + "&sender_email=" + $(this)[0].dataset.senderEmail + "&resending=true",
          type: "POST",
          success: function(data) {
           timeStamp.html(element[0].dataset.afterText);
           element.html(originalText);
          },
          error: function(data) {
            element.html(originalText);
            alert("could not resend the invitation, please try later");
          },
        });
      });
   
    
    
      //seding request manupulations with the modal info
      if( $(".request-testimonial").length > 0 ){
          var sendBtn = $("#modal-confirm-feedback a");
          var originalText = sendBtn.html();
             
          $(document).on("click", '.request-testimonial', function(){
             var name = $(this)[0].dataset.name;
             var id = $(this)[0].dataset.id;
             var link = 'request_testimonial/?id=' + id
             $('#modal-confirm-feedback #name-testimonial').html(name);
             sendBtn.removeClass("disabled");
             sendBtn.html(originalText);
             sendBtn.attr({'href': link });
             sendBtn.on("click", function(){
                var afterText = $(this)[0].dataset.afteraction;
                $(this).html(afterText);
                $(this).addClass("disabled");
             });
             
          });
      };
      
      
      
       //provisioning sub for the client with the modal info
    //   if( $(".provision-sub").length > 0 ){
    //       var sendBtn2 = $("#modal-provision-sub a");
    //       var originalText2 = sendBtn2.html();
             
    //       $(document).on("click", '.provision-sub', function(){
           
    //          var name = $(this)[0].dataset.name;
    //          var id = $(this)[0].dataset.id;
    //          var link = 'provision_subscription/?id=' + id;
    //          $('#modal-provision-sub #name-premium').html(name);
    //          sendBtn2.removeClass("disabled");
    //          sendBtn2.html(originalText2);
    //          sendBtn2.attr({'href': link });
    //          sendBtn2.on("click", function(){
    //             $('.spinner-provisions').show();
    //             $('.number-provisions').hide();
    //             var afterText = $(this)[0].dataset.afteraction;
    //             $(this).html(afterText);
    //             $(this).addClass("disabled");
    //          });
             
    //       });
    //   };
      
        //demoting sub for the client with the modal info
      if( $(".demote-sub, .provision-sub").length > 0 ){
          
             
          $(document).on("click", '.demote-sub, .provision-sub', function(){
             var id = $(this)[0].dataset.id;
             if ($(this).hasClass( "demote-sub" )){
                var sendBtn3 = $("#modal-demote-sub a.btn");
                var link = 'demote_subscription/?id=' + id
             } else if ($(this).hasClass( "provision-sub" )){
                var sendBtn3 = $("#modal-provision-sub a.btn"); 
                var link = 'provision_subscription/?id=' + id
                var trial = parseInt($(this)[0].dataset.trial);
                if (trial > 0){
                    $("#trial_time_info").show();
                    $("#trial_days_modal").html(trial);
                }else{
                    $("#trial_time_info").hide();
                };
             };
             var originalText3 = sendBtn3[0].dataset.beforeaction;
             var name = $(this)[0].dataset.name;
             var id = $(this)[0].dataset.id;
             var dateEnd = $(this)[0].dataset.end;
             
             $('#modal-demote-sub #name-premium, #modal-provision-sub #name-premium').html(name);
             $('#modal-demote-sub #end-date').html(dateEnd);
             sendBtn3.removeClass("disabled");
             sendBtn3.html(originalText3);
             sendBtn3.attr({'href': link });
             sendBtn3.on("click", function(){
                $('.spinner-provisions').show();
                $('.number-provisions').hide();
                var afterText = $(this)[0].dataset.afteraction;
                $(this).html(afterText);
                $(this).addClass("disabled");
             });
             
          });
      };
};